import {ethers} from "ethers";
import {erc20Mapping} from "../contractABI/erc20Mapping";
import {cguy} from "../contractABI/cguy";
import {priceFeed} from "../contractABI/priceFeed";
import {abi} from "../contractABI/erc20";
import {signer, treasury} from "../contractABI/treasury";
import {aave} from "../contractABI/aave";
import {ZERO_ADDRESS} from "./utilities";
import {contractAddress} from "./contract";
import {getIntrinsicValue} from "./treasury";

const getPermittedToken = async (useCache = false) => {
    if (useCache && sessionStorage.getItem("tokensList")) {
        // console.log('getPermittedToken: CACHE');
        return JSON.parse(sessionStorage.getItem("tokensList"));
    }
    // console.log('getPermittedToken: LIVE');
    const tokenCount = await erc20Mapping.getRegistryCount();
    let tokenList = [];
    for (let i = 0; i < tokenCount.toNumber(); i++) {
        let token = await erc20Mapping.getRegistryByIndex(i);
        if (await erc20Mapping.permissionsReserveToken(token)) {
            tokenList.push(token);
        }
    }
    sessionStorage.setItem('tokensList', JSON.stringify(tokenList));
    return tokenList;
};

const getTokenMeta = async (tokenAddress) => {
    const storage = JSON.parse(localStorage.getItem('tokensMeta')) ?? {};
    let tokensMeta = {...storage};

    try {
        const erc20 = new ethers.Contract(tokenAddress, abi, signer);
        const name = await erc20.name();
        const symbol = await erc20.symbol();
        const decimals = await erc20.decimals();

        tokensMeta[tokenAddress] = {
            name: name,
            symbol: symbol,
            decimals: decimals,
        }

        localStorage.setItem('tokensMeta', JSON.stringify(tokensMeta));
    } catch (e) {
        console.log(e);
    }

    return tokensMeta[tokenAddress];
}

const getTokenTotalSupply = async (tokenAddress) => {
    const storage = JSON.parse(localStorage.getItem('tokensTotalSupply')) ?? {};
    let tokensTotalSupply = {...storage};
    if (!tokensTotalSupply[tokenAddress]) {
        tokensTotalSupply[tokenAddress] = 0;
    }

    try {
        const erc20 = new ethers.Contract(tokenAddress, abi, signer);
        tokensTotalSupply[tokenAddress] = await erc20.totalSupply();
        localStorage.setItem('tokensTotalSupply', JSON.stringify(tokensTotalSupply));
    } catch (e) {
        console.log(e);
    }

    return tokensTotalSupply[tokenAddress];
}

const getTokenPrice = async (tokenAddress) => {
    const storage = JSON.parse(sessionStorage.getItem("tokensPrice")) ?? {};
    let tokenPrice = {...storage};
    const tokenMeta = await getTokenMeta(tokenAddress);
    const cguyAddress = await contractAddress('cguy');
    const tableCoinAddress = await contractAddress('tablecoin');
    try {
        let price = 0;
        if (tokenAddress.toLowerCase() === cguyAddress.toLowerCase()) {
            price = await treasury.cguyPrice();
        } else if (tokenAddress.toLowerCase() === tableCoinAddress.toLowerCase()) {
            price = await getIntrinsicValue();
        } else {
            price = await priceFeed.getAmountInUsd(
                tokenAddress,
                10 ** tokenMeta['decimals']
            )
        }
        tokenPrice[tokenAddress] = price / (10 ** 18);
        sessionStorage.setItem('tokensPrice', JSON.stringify(tokenPrice));
    } catch (e) {
        console.log(e);
    }
    return tokenPrice[tokenAddress];
}

const getDebtToken = async (tokenAddress, debtType) => {
    const storage = JSON.parse(localStorage.getItem('debtToken')) ?? {};
    let debtToken = {...storage};
    if (!debtToken[tokenAddress]) {
        debtToken[tokenAddress] = {};
    }
    try {
        if (debtType === 'stable') {
            debtToken[tokenAddress][debtType] = await aave.getStableDebtTokenAddress(tokenAddress);
        } else if (debtType === 'variable') {
            debtToken[tokenAddress][debtType] = await aave.getVariableDebtTokenAddress(tokenAddress);
        }
        localStorage.setItem('debtToken', JSON.stringify(debtToken));
    } catch (e) {
        console.log(e);
    }
    return debtToken[tokenAddress] ? debtToken[tokenAddress][debtType] : ZERO_ADDRESS;
}

export {getPermittedToken, getTokenMeta, getTokenPrice, getDebtToken, getTokenTotalSupply};